<!-- Nav.vue -->
<template>
  <div class="d-none d-lg-flex">
    <div
      :class="
        classExpanded == 'btn'
          ? 'sidebar-container'
          : 'sidebar-container-relative'
      "
      @mouseover="expandSidebar('')"
      @mouseout="collapseSidebar('')"
    >
      <div class="sidebar p-0" :class="{ expanded: isSidebarExpanded }">
        <div id="my-sidebar">
          <div class="sidebar-title">
            <b-navbar-brand href="#" class="mr-auto menu-logo">
              <b-img
                src="/img/logo-tickets.png"
                height="40"
                width="40"
                alt="Logo"
                fluid
              ></b-img>
              <span class="pl-3">Tickets Flow</span>
            </b-navbar-brand>
          </div>
          <b-nav class="sidebar-body" vertical v-if="isAuthenticated">
            <!--b-nav-item @click="goTo('/')">
          <b-icon-house-fill></b-icon-house-fill>
          Home
        </b-nav-item-->
            <!-- Item con subitems -->
            <b-nav-item 
            v-if="user.name && checkPermission('admin')"
            v-b-toggle.collapse1>
              <span>
                <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                <span :class="isSidebarExpanded ? 'span-visible' : 'span-hide'">
                  Admin
                </span>
              </span>
              <b-collapse id="collapse1" class="mt-2">
                <b-nav class="sub-item" vertical>
                  <b-nav-item v-if="checkPermission('users')" href="#">
                    <p @click="goTo('/admin/users')">
                      <b-icon-people-fill></b-icon-people-fill>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Usuarios
                      </span>
                    </p></b-nav-item
                  >
                  <b-nav-item v-if="checkPermission('roles')" href="#">
                     <p @click="goTo('/admin/roles')">
                      <b-icon-person-lines-fill></b-icon-person-lines-fill>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Roles
                      </span>
                    </p>
                  </b-nav-item>

                  <b-nav-item v-if="checkPermission('permissions')" href="#">
                    <p @click="goTo('/admin/permissions')">
                      <b-icon-list-ul></b-icon-list-ul>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Permisos
                      </span>
                    </p>
                  </b-nav-item>

                  <b-nav-item v-if="checkPermission('sesions')" href="#">
                    <p @click="goTo('/admin/sessions')">
                      <b-icon-person-circle></b-icon-person-circle>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Sesiones
                      </span>
                    </p></b-nav-item
                  >
                  <b-nav-item v-if="checkPermission('areas')" href="#">
                    <p @click="goTo('/admin/areas')">
                      <b-icon-person-badge-fill></b-icon-person-badge-fill>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Áreas
                      </span>
                    </p></b-nav-item
                  >
                  <b-nav-item 
                  v-if="checkPermission('expenses')" href="#">
                    <p @click="goTo('/admin/expenses')">
                      <b-icon-gear-wide-connected></b-icon-gear-wide-connected>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Gastos
                      </span>
                    </p></b-nav-item
                  >
                </b-nav>
              </b-collapse>
            </b-nav-item>

            <b-nav-item
              v-if="checkPermission('tickets')"
              v-b-toggle.tikets-menu
            >
              <div class="sidebar-item">
                <b-icon-chat-dots-fill></b-icon-chat-dots-fill>
                <span :class="isSidebarExpanded ? 'span-visible' : 'span-hide'">
                  Tickets
                </span>
              </div>
              <b-collapse id="tikets-menu" class="mt-2">
                <b-nav class="sub-item" vertical>
                  <b-nav-item @click="goTo('/tickets')">
                    <p>
                      <b-icon-list-check></b-icon-list-check
                      ><span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Listado
                      </span>
                    </p></b-nav-item
                  >
                  <b-nav-item @click="goTo('/tickets/new')"
                    ><p>
                      <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Nuevo
                      </span>
                    </p></b-nav-item
                  >
                  <b-nav-item v-if="user.name && (user.area_user.level || user.roles[0].id == 1 ) && (!area.non_resolving || user.id == 5)" @click="goTo('/tickets/statistics')"
                    ><p>
                      <b-icon-bar-chart-line></b-icon-bar-chart-line>
                      <span
                        :class="
                          isSidebarExpanded ? 'span-visible' : 'span-hide'
                        "
                      >
                        Estadísticas
                      </span>
                    </p></b-nav-item
                  >
                </b-nav>
              </b-collapse>
            </b-nav-item>

            <b-nav-item
              v-if="isAuthenticated"
              v-b-toggle.user-menu
              class="d-lg-none user-item-sidebar"
            >
              <span>
                <b-icon-person-fill></b-icon-person-fill>{{ user.name }}
              </span>
              <b-collapse id="user-menu" class="mt-2">
                <b-nav class="sub-item" vertical>
                  <b-col class="pl-4 pt-2 pb-2 info-user">
                    <div>
                      Área:
                      {{ area != undefined ? area.name : "Sin Área asignada" }}
                    </div>
                  </b-col>
                  <!--b-nav-item @click="goTo('/tickets')">
                <p>
                  <b-icon-file-person-fill></b-icon-file-person-fill> Configurar
                </p></b-nav-item
              -->
                  <b-nav-item @click="logout()"
                    ><p>
                      <b-icon-door-open-fill></b-icon-door-open-fill> Cerrar
                      Sesión
                    </p></b-nav-item
                  >
                </b-nav>
              </b-collapse>
            </b-nav-item>
          </b-nav>

          <b-container fluid>
            <b-row>
              <b-col>
                <slot></slot>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";

export default {
  name: "Nav",
  props: [],
  data() {
    return {
      pathSelected: null,
      isSidebarExpanded: false,
      classExpanded: null,
      isExpandedByBtn: false,
      isExpandedByOver: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.getUser;
    },
    area() {
      return this.$store.getters.getArea;
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(["changeValueLoading"]),
    checkPermission(permiso) {
      return this.permissions[permiso].status;
    },
    goTo(path) {
      if (this.$route.path == path) {
        return;
      }
      this.$router.push(path).catch(() => {});
    },
    expandSidebar(origen) {
      if (origen == "btn") {
        this.isExpandedByBtn = !this.isExpandedByBtn;
        this.classExpanded = "";
        this.isSidebarExpanded = !this.isSidebarExpanded;
        this.isExpandedByOver = false;
      } else {
        if (!this.isExpandedByBtn) {
          this.classExpanded = "btn";
          this.isExpandedByOver = true;
          this.isSidebarExpanded = !this.isSidebarExpanded;
        }
      }
    },
    collapseSidebar(origen) {
      if (!this.isExpandedByBtn) {
        this.isSidebarExpanded = false;
      }
    },
    async logout() {
      this.changeValueLoading(true);
      this.$store
        .dispatch("logout")
        .then((res) => {
          if (res.status == 200) {
            // Limpiar el token almacenado en localStorage
            localStorage.removeItem("token");
            this.$store.commit("setAuthentication", false);

            // Redirigir al usuario a la página de inicio de sesión
            this.$router.push("/login").catch(() => {});
          } else if (res.status == 403) {
          }
          this.changeValueLoading(false);
        })
        .catch((err) => {
          this.msg = err.response.data.msg;
          this.changeValueLoading(false);
        });
    },
  },
};
</script>

<style scoped>
.logo {
  cursor: pointer;
}
.info-user {
  background-color: #edf2f2;
  color: black;
}
.menu-logo {
  font-size: 16pt !important;
  color: white !important;
}
.navbar {
  background-color: #005187;
}
.my-sidebar {
  background-color: #005187 !important;
  color: #ecf0f1;
}
#my-sidebar .nav-link {
  color: #ecf0f1 !important;
}
#my-sidebar .nav-link:hover {
  background-color: #3498db;
  color: #ecf0f1 !important;
}
.sidebar-title {
  padding: 4pt 8pt 0 8pt;
  border-color: #88aab2;
  border-width: 0 0 1px 0;
  border-style: solid;
  background-color: #005187 !important;
}
.sub-item * :hover {
  color: black !important;
}
p {
  margin: 0;
}
.user-item-sidebar {
  background-color: #06485e;
  margin-top: 10pt;
}
.user-item-sidebar :hover {
  background-color: #053b4d !important;
}
</style>
