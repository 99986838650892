<template>
  <div class="container">
    <main-template :titulo="'Permisos'">
      <b-col cols="12" class="p-0">
        <b-row>
          <b-col cols="4">
            <b-input-group size="sm" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                size="sm"
                type="search"
                placeholder="Search terms"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col align="right" cols="8">
            <b-button
              @click="showModal"
              size="sm"
              title="Agregar Permiso"
              variant="outline-primary"
            >
              <b-icon icon="plus-lg"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <custom-table
        :items="items"
        :show="showLoading"
        :pagination="true"
        :perPage="perPage"
        :currentPage="currentPage"
        :fields="fields"
        :keys="['actions']"
        :filter="filter"
      >
        <!--template #actions="{ item }">
          <b-icon
            @click="getDetails(item)"
            icon="eye-fill"
            class="h6 m-0 pointer"
            variant="info"
          ></b-icon>
          <-b-icon
            @click="editPermission(item)"
            icon="pencil-fill"
            class="h6 m-0 pointer"
            variant="warning"
          ></b-icon>
        </template-->
      </custom-table>
    </main-template>

    <!-- MODAL CREAR/EDITAR Permiso -->
    <b-modal
      v-model="modalVisible"
      :title="`${isEdit ? 'Editar' : 'Crear'} Permiso`"
      size="xl"
      hide-footer
    >
      <b-overlay rounded="xl" :show="showLoadingModal">
        <b-container fluid>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Nombre:" label-for="nombre">
                <b-form-input
                  size="sm"
                  id="nombre"
                  v-model="permission.name"
                  placeholder="Ingrese su nombre"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group label="Descripción:" label-for="description">
                <b-form-input
                  size="sm"
                  id="description"
                  v-model="permission.description"
                  placeholder="Descripción"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <b-button size="sm" @click="modalVisible = false" variant="danger"
                >CANCELAR</b-button
              >
            </b-col>
            <b-col align="right">
              <b-button size="sm" @click="savePermission()" variant="primary">{{
                isEdit ? "EDITAR" : "CREAR"
              }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import MainTemplate from "@/components/MainTemplate.vue";
import CustomTable from "@/components/tables/CustomTable.vue";

export default {
  components: { MainTemplate, CustomTable },
  data() {
    return {
      isEdit: false,
      showLoading: false,
      showLoadingModal: false,
      items: [],
      fields: [
        { key: "id", label: "#", thStyle: { width: "30px" }, sortable: true },
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "70px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "45px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
        },
      ],

      perPage: 20,
      currentPage: 1,
      filter: null,

      modalVisible: false,
      permission: {
        id: null,
        name: "",
        description: null,
      },
      permissions: [],
    };
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    editPermission(permission) {
      this.isEdit = true;
      this.permission = {
        id: permission.id,
        name: permission.name,
        description: permission.description,
      };
      this.showModal();
    },
    getPermissions() {
      this.showLoading = true;

      this.$axios
        .get("/admin/getPermissions")
        .then((response) => {
          this.items = [...response.data];
          this.showLoading = false;
        })
        .catch((err) => {
          this.showLoading = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    getDetails() {},
    showModal() {
        //this.isEdit = false;
      this.modalVisible = true;
    },
    savePermission() {
      if (this.isEdit) {
        this.updatePermission();
      } else {
        this.createPermission();
      }
      this.clearForm();
    },
    updatePermission() {
      this.showLoadingModal = true;
      this.$axios
        .post("/admin/update", {
          // Aquí debes enviar los datos del formulario
          id: this.permission.id,
          name: this.permission.name,
          description: this.permission.description,
          //password: this.user.password,
        })
        .then((response) => {
          this.getRoles();
          this.modalVisible = false;
          this.showLoadingModal = false;
          this.clearForm();
        })
        .catch((err) => {
          this.showLoadingModal = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    createPermission() {
      this.showLoadingModal = true;
      this.$axios
        .post("/admin/createPermissions", {
          // Aquí debes enviar los datos del formulario
          name: this.permission.name,
          description: this.permission.description,
        })
        .then((response) => {
          this.getPermissions();
          this.modalVisible = false;
          this.showLoadingModal = false;
          this.clearForm();
        })
        .catch((err) => {
          this.showLoadingModal = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    clearForm() {
      this.permission = {
        id: null,
        name: "",
        description: null,
      };
      this.isEdit = false;
    },
  },
};
</script>
<style scoped>
</style>