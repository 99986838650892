<!-- Nav.vue -->
<template>
  <div>
    <b-navbar toggleable="lg" type="dark" class="navbar">
      <b-button v-b-toggle.my-sidebar class="sidebar-toggle">
        <b-icon-list></b-icon-list>
      </b-button>
      <b-navbar-brand  @click="goTo('/tickets')" class="mr-auto logo">
        <b-img
          src="/img/logo-tickets.png"
          height="35"
          width="35"
          alt="Logo"
          fluid
        ></b-img>
        Tickets Flow
      </b-navbar-brand>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto"></b-navbar-nav>
      </b-collapse>

      <!-- Contenido de la barra de navegación -->
      <b-navbar-nav v-if="isAuthenticated" class="ml-auto d-none d-lg-flex">
        <!-- Agrega el icono de usuario y el menú desplegable -->
        <b-nav-item-dropdown right>
          <!-- Icono de usuario -->
          <template v-slot:button-content>
            <b-icon-person-fill></b-icon-person-fill>{{ user.name }}
          </template>
          <b-col class="pl-4 pt-2 pb-2 info-user">
            <div>Área: {{ area != undefined ? area.name : 'Sin Área asignada' }}</div>
          </b-col>
          <!-- Contenido del menú desplegable -->
          <!--b-dropdown-item @click="logout">
            <b-icon-file-person-fill></b-icon-file-person-fill>
            Configurar</b-dropdown-item
          -->
          <b-dropdown-item @click="logout"
            ><b-icon-door-open-fill></b-icon-door-open-fill> Cerrar
            Sesión</b-dropdown-item
          >
          <!-- Puedes agregar más opciones aquí según tus necesidades -->
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>

    <b-sidebar fixed class="my-sidebar" id="my-sidebar" title="Menú" no-header shadow>
      <div class="sidebar-title">
        <b-navbar-brand href="#" class="mr-auto menu-logo">
          <b-img
            src="/img/logo-tickets.png"
            height="50"
            width="50"
            alt="Logo"
            fluid
          ></b-img>
          Tickets Flow
        </b-navbar-brand>
        <b-button v-b-toggle.my-sidebar class="sidebar-toggle">
          <b-icon-list></b-icon-list>
        </b-button>
      </div>
      <b-nav class="sidebar-body" vertical v-if="isAuthenticated">
        <!--b-nav-item @click="goTo('/')">
          <b-icon-house-fill></b-icon-house-fill>
          Home
        </b-nav-item-->
        <!-- Item con subitems -->
        <b-nav-item v-if="checkPermission('admin')" v-b-toggle.collapse1>
          <span>
            <b-icon icon="gear-fill" aria-hidden="true"></b-icon> Admin
          </span>
          <b-collapse id="collapse1" class="mt-2">
            <b-nav class="sub-item" vertical>
              <b-nav-item  v-if="checkPermission('users')" href="#">
                <p @click="goTo('/admin/users')">
                  <b-icon-people-fill></b-icon-people-fill> Usuarios
                </p></b-nav-item
              >
              <b-nav-item v-if="checkPermission('roles')" href="#"
                ><p @click="goTo('/admin/roles')">
                  <b-icon-person-lines-fill></b-icon-person-lines-fill> Roles
                </p></b-nav-item
              >
              <b-nav-item class="itemSub" v-if="checkPermission('permissions')" href="#"
                ><p @click="goTo('/admin/permissions')">
                  <b-icon-list-ul></b-icon-list-ul> Permisos
                </p></b-nav-item
              >
              <b-nav-item href="#" v-if="checkPermission('sesions')"
                ><p @click="goTo('/admin/sessions')">
                  <b-icon-person-circle></b-icon-person-circle> Sesiones
                </p></b-nav-item
              >
              <b-nav-item href="#" v-if="checkPermission('areas')"
                ><p @click="goTo('/admin/areas')">
                  <b-icon-person-badge-fill></b-icon-person-badge-fill> Areas
                </p></b-nav-item
              >
              <b-nav-item href="#" v-if="checkPermission('expenses')"
                ><p @click="goTo('/admin/expenses')">
                  <b-icon-person-badge-fill></b-icon-person-badge-fill> Gastos
                </p></b-nav-item
              >
            </b-nav>
          </b-collapse>
        </b-nav-item>

        <b-nav-item v-if="checkPermission('tickets')" v-b-toggle.tikets-menu>
          <span> <b-icon-chat-dots-fill></b-icon-chat-dots-fill> Tickets </span>
          <b-collapse id="tikets-menu" class="mt-2">
            <b-nav class="sub-item" vertical>
              <b-nav-item @click="goTo('/tickets')">
                <p>
                  <b-icon-list-check></b-icon-list-check> Listado
                </p></b-nav-item
              >
              <b-nav-item @click="goTo('/tickets/new')"
                ><p>
                  <b-icon-plus-circle-fill></b-icon-plus-circle-fill> Nuevo
                </p></b-nav-item
              >
              <b-nav-item v-if="user && (user.area_user.level || user.roles[0].id == 1) && (!area.non_resolving || user.id == 5)" @click="goTo('/tickets/statistics')"
                ><p>
                  <b-icon-bar-chart-line></b-icon-bar-chart-line> Estadísticas
                </p></b-nav-item
              >
            </b-nav>
          </b-collapse>
        </b-nav-item>

        <b-nav-item
          v-if="isAuthenticated"
          v-b-toggle.user-menu
          class="d-lg-none user-item-sidebar"
        >
          <span>
            <b-icon-person-fill></b-icon-person-fill>{{ user.name }}
          </span>
          <b-collapse id="user-menu" class="mt-2">
            <b-nav class="sub-item" vertical>
              <b-col class="pl-4 pt-2 pb-2 info-user">
                <div>Área: {{ area != undefined ? area.name : 'Sin Área asignada' }}</div>
              </b-col>
              <!--b-nav-item @click="goTo('/tickets')">
                <p>
                  <b-icon-file-person-fill></b-icon-file-person-fill> Configurar
                </p></b-nav-item
              -->
              <b-nav-item @click="logout()"
                ><p>
                  <b-icon-door-open-fill></b-icon-door-open-fill> Cerrar Sesión
                </p></b-nav-item
              >
            </b-nav>
          </b-collapse>
        </b-nav-item>
      </b-nav>
    </b-sidebar>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";

export default {
  name: "Nav",
  data() {
    return {
      pathSelected: null,
    };
  },
  computed: {
    ...mapState(["permissions"]),

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.getUser;
    },
    area() {
      return this.$store.getters.getArea;
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(["changeValueLoading"]),

    checkPermission(permiso) {
      return this.permissions[permiso].status;
    },
    goTo(path) {
      if(this.$route.path == path){
        return;
      }
      this.$router.push(path).catch(() => {});
    },
    async logout() {
      this.changeValueLoading(true);
      this.$store
        .dispatch("logout")
        .then((res) => {
          if (res.status == 200) {
            // Limpiar el token almacenado en localStorage
            localStorage.removeItem("token");
            this.$store.commit("setAuthentication", false);

            // Redirigir al usuario a la página de inicio de sesión
            this.$router.push("/login").catch(() => {});
          } else if (res.status == 403) {
          }
          this.changeValueLoading(false);
        })
        .catch((err) => {
          this.msg = err.response.data.msg;
          this.changeValueLoading(false);
        });
    },
  },
};
</script>

<style scoped>
.logo{
  cursor: pointer;
}
.info-user {
  background-color: #edf2f2;
  color: black;
}
.menu-logo {
  font-size: 16pt !important;
  color: white !important;
}

.navbar {
  background-color: #005187;  
    position: absolute;
    width: 100%;
    z-index: 5;
    display: none;
}
@media (max-width: 991px) {
  .navbar {
    display: block;
  }
}
.my-sidebar {
  background-color: #005187 !important;
  color: #ecf0f1;
}

#my-sidebar .nav-link {
  color: #ecf0f1 !important;
}

#my-sidebar .nav-link:hover {
  background-color: #3498db;
  color: #ecf0f1 !important;
}

.sidebar-toggle {
  position: relative;
  padding-left: 5pt;
  color: #3498db;
  background-color: #005187 !important;
  border-color: #005187 !important;
}

.sidebar-title {
  padding: 8pt;
  border-color: #88aab2;
  border-width: 0 0 1px 0;
  border-style: solid;
  background-color: #005187 !important;
}
.sub-item * :hover {
  color: black !important;
}
p {
  margin: 0;
}
.user-item-sidebar {
  background-color: #06485e;
  margin-top: 10pt;
}
.user-item-sidebar :hover {
  background-color: #053b4d !important;
}
</style>
