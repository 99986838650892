<template>
  <div class="container">
    <main-template :titulo="'Roles'">
      <b-col cols="12" class="p-0">
        <b-row>
          <b-col cols="4">
            <b-input-group size="sm" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                size="sm"
                type="search"
                placeholder="Search terms"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col align="right" cols="8">
            <b-button
              @click="clearForm(); showModal()"
              size="sm"
              title="Agregar rol"
              variant="outline-primary"
            >
              <b-icon icon="plus-lg"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <custom-table
        :items="items"
        :show="showLoading"
        :pagination="true"
        :perPage="perPage"
        :currentPage="currentPage"
        :fields="fields"
        :keys="['actions']"
        :filter="filter"
      >
        <template #actions="{ item }">
          <!--b-icon
            @click="getDetails(item)"
            icon="eye-fill"
            class="h6 m-0 pointer"
            variant="info"
          ></b-icon-->
          <b-icon
            @click="editRol(item)"
            icon="pencil-fill"
            class="h6 m-0 pointer"
            variant="warning"
          ></b-icon>
        </template>
      </custom-table>
    </main-template>

    <!-- MODAL CREAR/EDITAR ROL -->
    <b-modal
      v-model="modalVisible"
      :title="`${isEdit ? 'Editar' : 'Crear'} Rol`"
      size="xl"
      hide-footer
    >
      <b-overlay rounded="xl" :show="showLoadingModal">
        <b-container fluid>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Nombre:" label-for="nombre">
                <b-form-input
                  size="sm"
                  :disabled="isEdit"
                  id="nombre"
                  v-model="rol.name"
                  placeholder="Ingrese el nombre"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <custom-table
            v-if="isEdit"
            :items="itemsPermissions"
            :show="showLoading"
            :fields="fieldsPermissions"
            :keys="['actions']"
            :filter="filterPermission"
          >
            <template #actions="{ item }">
              <b-form-checkbox v-model="item.check" name="check-button" switch>
              </b-form-checkbox>
            </template>
          </custom-table>
          <b-row class="mt-3">
            <b-col>
              <b-button size="sm" @click="modalVisible = false" variant="danger"
                >CANCELAR</b-button
              >
            </b-col>
            <b-col align="right">
              <b-button size="sm" @click="saveRol()" variant="primary">{{
                isEdit ? "EDITAR" : "CREAR"
              }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import MainTemplate from "@/components/MainTemplate.vue";
import CustomTable from "@/components/tables/CustomTable.vue";

export default {
  components: { MainTemplate, CustomTable },
  data() {
    return {
      isEdit: false,
      showLoading: false,
      showLoadingModal: false,
      items: [],
      fields: [
        { key: "id", label: "#", thStyle: { width: "30px" }, sortable: true },
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "70px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "45px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
        },
      ],

      perPage: 20,
      currentPage: 1,
      filter: null,
      itemsPermissions: [],
      fieldsPermissions: [
        {
          key: "actions",
          label: "",
          thStyle: { width: "45px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
        },
        { key: "id", label: "#", thStyle: { width: "30px" }, sortable: true },
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "70px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "80px" },
          sortable: true,
        },
      ],
      filterPermission: null,
      modalVisible: false,
      rol: {
        id: null,
        name: "",
        decription: null,
        permissions: [],
      },
      roles: [],
      rolePermissions: [],
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    editRol(rol) {
      this.isEdit = true;
      this.rol = {
        id: rol.id,
        name: rol.name,
        permissions: rol.permissions,
      };
      this.showModal();
    },
    getRoles() {
      this.showLoading = true;

      this.$axios
        .get("/admin/getRoles")
        .then((response) => {
          this.items = [...response.data.roles];
          this.showLoading = false;
        })
        .catch((err) => {
          this.showLoading = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    getDetails() {},
    showModal() {
      if (!this.isEdit) {
        this.clearForm();
      }
      this.showLoadingModal = true;
      this.modalVisible = true;
      if (this.isEdit) {
        this.$axios
          .get("/admin/getPermissions")
          .then((response) => {
            this.itemsPermissions = response.data;
            this.setRolePermissions();
            this.showLoadingModal = false;
          })
          .catch((err) => {
            this.showLoadingModal = false;
            //this.msjModal = "Error! " + err;
            //this.$refs["msj-modal"].show();
          });
      } else {
        this.showLoadingModal = false;
      }
    },
    setRolePermissions() {
      this.itemsPermissions.map((permission) => {
        const permissionRol = this.rol.permissions.find(
          (id) => id == permission.id
        );

        if (permissionRol) {
          permission.check = true;
          permission.activeRol = true;
        } else {
          permission.check = false;
          permission.activeRol = false;
        }
        return permission;
      });
    },
    saveRol() {
      if (this.isEdit) {
        this.updateRol();
      } else {
        this.createRol();
      }
    },
    updateRol() {
      this.showLoadingModal = true;
      const updatedListPermissions = this.getUpdatedListOfPermissions();
      this.$axios
        .post("/admin/updateRol", {
          id: this.rol.id,
          name: this.rol.name,
          new_list_permissions: updatedListPermissions.newPermissionsList,
          remove_list_permissions: updatedListPermissions.removePermissionsList,
        })
        .then((response) => {
          this.getRoles();
          this.modalVisible = false;
          this.showLoadingModal = false;
          this.clearForm();
        })
        .catch((err) => {
          this.showLoadingModal = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    getUpdatedListOfPermissions() {
      const newPermissionsList = [];
      const removePermissionsList = [];

      this.itemsPermissions.forEach((permission) => {
        if (!permission.check && permission.activeRol) {
          removePermissionsList.push(permission.id);
        } else if (permission.check && !permission.activeRol) {
          newPermissionsList.push(permission.id);
        }
      });
      return {
        newPermissionsList: newPermissionsList,
        removePermissionsList: removePermissionsList,
      };
    },
    createRol() {
      this.showLoadingModal = true;
      this.$axios
        .post("/admin/createRol", {
          // Aquí debes enviar los datos del formulario
          name: this.rol.name,
        })
        .then((response) => {
          this.getRoles();
          this.modalVisible = false;
          this.showLoadingModal = false;
          this.clearForm();
        })
        .catch((err) => {
          this.showLoadingModal = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    clearForm() {
      this.rol = {
        id: null,
        name: "",
      };
      this.isEdit = false;
    },
  },
};
</script>
<style scoped>
</style>