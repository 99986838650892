<template>
  <div class="container">
    <b-card class="mt-2">
      <b-col cols="12">
        <b-row>
          <b-col cols="8">
            <h5>Tickets - Nuevo</h5>
          </b-col>
          <b-col align="right" md="4" sm="12">
            <b-button @click="goToOrders" size="sm" variant="outline-primary">
              <b-icon icon="list-ul" variant="primary"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mt-2">
        <b-col sm="12" md="12">
          <b-form-text for="select-state"> Área destino</b-form-text>
          <b-form-select
            size="sm"
            v-model="selectArea"
            :options="optionsAreas"
            @change="verifyArea"
          ></b-form-select>
        </b-col>
        <div style="background-color: #f9f9f9">
          <b-col class="mt-2" v-if="isSameAreaUser">
            <b-form-checkbox
              v-model="enableListOrigen"
              :value="true"
              :unchecked-value="false"
              @change="setDefaultValues()"
            >
              Agregar Área de origen
            </b-form-checkbox>
          </b-col>
          <b-col class="mt-2" v-if="selectArea == 3">
            <b-form-checkbox
              v-model="isBankingInquiry"
              :value="true"
              :unchecked-value="false"
            >
              Consulta Bancaria
            </b-form-checkbox>
          </b-col>

          <b-col v-if="enableListOrigen" sm="12" md="12" class="pb-2">
            <b-form-text for="select-state"> Área origen</b-form-text>
            <b-form-select
              size="sm"
              v-model="selectAreaOrigen"
              :options="optionsAreasOrigen"
            ></b-form-select>
          </b-col>
        </div>

        <b-col sm="12" md="12">
          <b-form-text for="select-state"> Descripción </b-form-text>
          <b-form-textarea
            style="overflow: auto"
            @input="checkLenght()"
            id="textarea"
            v-model="description"
            :state="description.length >= 2"
            placeholder="Ingresar mínimo 2 caracteres"
            rows="3"
            max-rows="12"
            maxlength="300"
          ></b-form-textarea>
          <div align="right" style="font-size: 8pt" variant="light">
            {{ description ? description.length : 0 }}-300
          </div>
        </b-col>
        <b-col sm="12" md="12">
          <b-form-text for="select-state"> Foto/Archivo </b-form-text>
          <b-form-file
            @input="checkFile"
            multiple
            v-model="files"
            placeholder="Seleccione los archivos..."
            accept=".webp, .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
          >
          </b-form-file>
          <b-col cols="12" class="p-0">
            <b-row style="border-style: none">
              <b-col
                cols="10"
                style="font-size: 8pt; color: gray"
                variant="light"
              >
                El tamaño máximo permitido para cada archivo es de 3MB, con un
                límite de 5 archivos en total.
              </b-col>
              <b-col
                align="right"
                cols="2"
                style="font-size: 8pt; color: gray"
                variant="light"
              >
                {{ filesAccumulated.length }}-5
              </b-col>
            </b-row>
          </b-col>
          <div>
            <ul class="p-0">
              <div v-for="(thisFile, index) in filesAccumulated" :key="index">
                <b-badge
                  @click="filesAccumulated.splice(index, 1)"
                  href="#"
                  variant="info"
                  ><i class="fas fa-trash-alt"></i> {{ thisFile.name }}</b-badge
                >
              </div>
            </ul>
          </div>
        </b-col>

        <b-col v-if="selectArea == 12">
          <b-row>
            <b-col sm="6">
              <b-form-text for="select-state">Sucursal</b-form-text>
              <b-form-select
                size="sm"
                v-model="selectBranch"
                :options="optionsABranch"
              ></b-form-select>
            </b-col>

            <b-col sm="6">
              <b-form-text for="select-state">Sector</b-form-text>
              <b-form-input
                size="sm"
                v-model="inputSector"
                placeholder="Ingrese un Sector"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>

        <b-col align="center" class="mt-3">
          <b-overlay
            :show="showLoading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="lg"
          >
            <b-button
              :disabled="checkIsReadyToSave()"
              id="btnAcept"
              size="sm"
              variant="info"
              @click="saveOrder()"
              >Crear</b-button
            >
          </b-overlay>
        </b-col>
      </b-col>
    </b-card>

    <!-- Msjs modal -->
    <b-modal id="msj-modal" ref="msj-modal" centered hide-footer hide-header>
      <div class="d-block text-center p-4">
        <h4>{{ msjModal }}</h4>
      </div>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        size="sm"
        @click="$bvModal.hide('msj-modal')"
        >OK</b-button
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["rol"],
  data() {
    return {
      enableListOrigen: false,
      selectArea: 0,
      optionsAreas: [],
      optionsAreasOrigen: [],
      selectAreaOrigen: 0,
      description: "",
      //file1: null,
      files: [],
      filesAccumulated: [],
      isReadyToSave: false,
      userId: null,
      areaId: null,

      msjModal: null,
      showLoading: false,
      isSameAreaUser: false,
      isBankingInquiry: false,
      selectBranch: 0,
      optionsABranch: [
        { text: "Seleccione una Sucursal...", value: 0 },
        { value: "Ford Peron", text: "Ford Peron" },
        { value: "Nissan Tuc", text: "Nissan Tuc" },
        { value: "Nissan Santiago", text: "Nissan Santiago" },
        { value: "mundo ag", text: "mundo ag" },
        { value: "chery/jac", text: "chery/jac" },
      ],
      inputSector: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    area() {
      return this.$store.getters.getArea;
    },
  },
  mounted() {
    this.getUser();
    //this.getArea();
    this.getAreas();
  },
  methods: {
    checkLenght() {
      if (this.description.length == 300) {
        this.msjModal = "Alcanzó la cantidad máxima de caracteres";
        this.$refs["msj-modal"].show();
      }
    },
    setDefaultValues() {},
    verifyArea() {
      if (this.selectArea == this.area.id) {
        this.isSameAreaUser = true;
      } else {
        this.isSameAreaUser = false;
        this.selectAreaOrigen = 0;
      }
      //Verificamos si es contaduría, para mostrar el check del tipo
      if (this.selectArea == 3) {
        this.isBankingInquiry = true;
      } else {
        this.isBankingInquiry = false;
      }
    },
    checkFile() {
      this.files.forEach((file, index) => {
        if (this.filesAccumulated.length >= 5) {
          this.msjModal =
            "Ya alcanzó la cantidad máxima de archivos permitidos";
          this.$refs["msj-modal"].show();
          return;
        }
        if (file.size > 3145728) {
          // 5mb
          this.msjModal = "El archivo excede el tamaño máximo permitido (3mb)";
          this.$refs["msj-modal"].show();
          this.files.splice(index, 1);
        } else {
          this.filesAccumulated.push(file);
          this.files = [];
        }
      });
    },
    saveOrder() {
      let areaOrigen = null;
      //console.log(this.selectAreaOrigen);
      if (this.selectAreaOrigen) {
        areaOrigen = this.selectAreaOrigen;
      } else {
        areaOrigen = this.area.id;
      }
      //console.log(areaOrigen);
      let formData = new FormData();
      formData.append("area_id", this.selectArea);
      formData.append("area_origen_id", areaOrigen);
      formData.append("description", this.description);
      formData.append("id_user", this.user.id);
      formData.append("is_banking_inquiry", this.isBankingInquiry);
      formData.append("branch", this.selectBranch);
      formData.append("sector", this.inputSector);

      for (var i = 0; i < this.filesAccumulated.length; i++) {
        let file = this.filesAccumulated[i];
        formData.append("files[" + i + "]", file);
      }
      this.showLoading = true;
      this.$axios
        .post("/order/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.msjModal = response.data;
          this.$refs["msj-modal"].show();
          this.showLoading = false;
          this.clear();
        });
    },
    getAreas() {
      this.$axios
        .get("/admin/areas", {
          params: {
            area_id: this.area.id,
          },
        })
        .then((response) => {
          let areas = response.data.areas;
          this.formatOptionsSelectAreas(areas);
        });
    },
    checkIsReadyToSave() {
      if (
        //!this.files.length ||
        this.selectArea == 0 ||
        this.description == null ||
        !this.description ||
        this.description.length < 2
      ) {
        return true;
      }

      if (
        this.selectArea == 12 &&
        (this.selectBranch == 0 || this.inputSector == "")
      ) {
        return true;
      }
      return false;
    },

    handleFileUpload() {
      this.file = event.target.files[0];
      // this.file = this.$refs.file.files[0];
      if (this.file) this.isReadyToSave = true;
    },
    getUser() {
      this.userId = this.user.id;
    },
    goToOrders() {
      window.location.href = "/tickets";
    },
    formatOptionsSelectAreas(areas) {
      let destinationAreas = [];
      let origenAreas = [];
      destinationAreas = [{ text: "Seleccione un Área...", value: 0 }];
      origenAreas = [{ text: "Seleccione un Área...", value: 0 }];

      areas.forEach((e) => {
        !e.non_resolving &&
          destinationAreas.push({
            value: e.id,
            text: e.name,
          });
        origenAreas.push({
          value: e.id,
          text: e.name,
        });
      });
      this.optionsAreas = destinationAreas;
      this.optionsAreasOrigen = origenAreas;
      this.selectDeposit = 0;
    },
    clear() {
      this.description = "";
      this.selectArea = 0;
      this.selectAreaOrigen = 0;
      this.filesAccumulated = [];
      this.setDefaultValues();
      this.isSameAreaUser = false;
      this.enableListOrigen = false;
      this.isBankingInquiry = false;
    },
  },
};
</script>