<style>
.table-row {
  background-color: #f6f6f6;
}
.cell-info {
  background-color: #e5f0ff;
}
.table-row-selected {
  background-color: #eafee1;
}
.pointer {
  cursor: pointer;
}
</style>
<template>
  <b-card>
    <div>
      <b-row>
        <b-col md="9" sm="12" cols="6">
          <h5>Tickets - Estadísticas</h5>
        </b-col>

        <template>
          <b-col align="right" md="3" sm="12">
            <b-button
              class="mr-3"
              @click="showFilters = !showFilters"
              title="Filtros"
              size="sm"
              variant="outline-primary"
            >
              <i class="fas fa-filter"></i>
            </b-button>
            <b-button @click="goToList" size="sm" variant="outline-primary">
              <b-icon icon="list-ul" variant="primary"></b-icon>
            </b-button>
          </b-col>
        </template>
      </b-row>

      <b-overlay :show="show" rounded="sm">
        <b-col class="pt-2 pb-2 pl-0 pr-0">
          <b-collapse v-model="showFilters" class="filter-collapse">
            <b-col cols="12">
              <b-row>
                <b-row>
                  <!-- Radio buttons para seleccionar el modo de entrada -->
                  <b-col cols="12" class="pr-4">
                    <b-form-group>
                      <b-form-text class="mt-0"> RANGO DE TIEMPO </b-form-text>
                      <b-form-radio-group
                        v-model="selectionMode"
                        name="selectionMode"
                        @change="clearInputs"
                        aria-required="true"
                        stacked
                      >
                        <b-form-radio value="days">Días</b-form-radio>
                        <b-form-radio value="dates">Fechas</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Selector de rango de días -->
                <b-col cols="2" v-if="isDaysRangeEnabled">
                  <b-form-text class="mt-0"> RANGO DE DÍAS </b-form-text>
                  <b-form-group>
                    <b-form-select
                      size="sm"
                      v-model="dateRangeSelected"
                      :options="dateRangeList"
                      required
                      @change="getTicketsFilterRange()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <!-- Selector de rango de fechas -->
                <b-col cols="5" v-if="isDateRangeEnabled">
                  <b-row>
                    <b-col cols="12" md="12" sm="12" xl="6" lg="6" class="pr-0">
                      <b-form-text class="mt-0"> DESDE </b-form-text>
                      <b-form-datepicker
                        placeholder="dd/mm/aaaa"
                        locale="es"
                        size="sm"
                        v-model="startDate"
                        @input="
                          endDate = '';
                          items = [];
                        "
                        v-on:change="items = []"
                        required
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                      ></b-form-datepicker>
                    </b-col>
                    <b-col cols="12" md="12" sm="12" xl="6" lg="6" class="pr-0">
                      <b-form-text class="mt-0"> HASTA </b-form-text>
                      <b-form-datepicker
                        placeholder="dd/mm/aaaa"
                        locale="es"
                        size="sm"
                        v-model="endDate"
                        required
                        :state="isEndDateValid"
                        @input="getTicketsFilterRange()"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback>
                        La fecha "Hasta" no puede ser menor que la fecha
                        "Desde".
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Check para mostrar los verificados -->
                <b-col
                  cols="2"
                  v-if="area.id == 3 || user.roles[0].id == 1 || user.id == 5"
                >
                  <b-form-text class="mt-0"> TIPO </b-form-text>
                  <b-form-group>
                    <b-form-select
                      size="sm"
                      v-model="typeSelected"
                      :options="typeList"
                      required
                      @change="getTicketsFilterRange()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- Para el area MANTENIMIENTO -->
                <b-col
                  cols="2"
                  v-if="area.id == 12 || user.roles[0].id == 1 || user.id == 5"
                >
                  <b-form-text class="mt-0"> TIPO DE GASTO </b-form-text>
                  <b-form-group>
                    <b-form-select
                      size="sm"
                      v-model="categorySelected"
                      :options="categories"
                      required
                      @change="getTicketsFilterRange()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-collapse>
        </b-col>
        <b-row class="pt-2">
          <div v-if="!area.non_resolving || user.id == 5">
            <b-col cols="12">
              <h6 class="pl-3 mt-1 subtitle">
                <b-row>
                  <b-col cols="11"> Recibidos </b-col>
                  <b-col
                    v-if="items.length"
                    cols="1"
                    class="pointer"
                    align="right"
                  >
                    <b-icon
                      title="Exportar excel"
                      @click="downloadExcel(items, 'received')"
                      style="color: white"
                      icon="file-earmark-excel-fill"
                    ></b-icon>
                  </b-col>
                </b-row>
              </h6>
            </b-col>
            <b-col md="12" class="pb-2">
              <b-table
                class="table-rotation"
                responsive
                head-variant="light"
                fixed
                bordered
                small
                striped
                hoverstriped
                hover
                :items="items"
                :fields="fields"
              >
                <template #cell(actions)="row">
                  <b-icon
                    v-if="!isBoss"
                    @click="getAgentsByArea(row.item)"
                    icon="eye-fill"
                    class="border border-info rounded h6 m-0 pointer"
                    variant="info"
                  ></b-icon>
                </template>
              </b-table>
            </b-col>
          </div>
        </b-row>
        <b-row class="pt-2">
          <div v-if="selectedArea">
            <b-col cols="12">
              <h6 class="pl-3 mt-1 subtitle">
                <b-row>
                  <b-col cols="11">
                    Agentes de {{ selectedArea.area_destino ?? "" }}
                  </b-col>
                  <b-col
                    v-if="itemsByAgents && itemsByAgents.length"
                    cols="1"
                    class="pointer"
                    align="right"
                  >
                    <b-icon
                      title="Exportar excel"
                      @click="downloadExcel(itemsByAgents, 'agent')"
                      style="color: white"
                      icon="file-earmark-excel-fill"
                    ></b-icon>
                  </b-col>
                </b-row>
              </h6>
            </b-col>
            <b-col cols="3" v-if="selectedArea.areaId == 12">
              <b-form-text class="mt-0"> ESTADO </b-form-text>
              <b-form-group>
                <b-form-select
                  size="sm"
                  v-model="statusSelected"
                  :options="statusList"
                  required
                  @change="filterAgentsTicketsByStatus()"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="12" class="pb-2" ref="agentsTable">
              <b-table
                class="table-rotation"
                responsive
                head-variant="light"
                fixed
                bordered
                small
                striped
                hoverstriped
                hover
                :items="itemsByAgents"
                :fields="fieldsAgents"
              >
                <template #cell(actions)="row">
                  <b-icon
                    @click="getTicketsFromAgent(row.item)"
                    icon="eye-fill"
                    class="border border-info rounded h6 m-0 pointer"
                    variant="info"
                  ></b-icon>
                </template>
                <template #cell(total_cost_en_proceso)="row">
                  {{
                    `$${
                      row.item.total_cost_en_proceso
                        ? Intl.NumberFormat("es-AR").format(
                            parseFloat(row.item.total_cost_en_proceso)
                          )
                        : 0
                    }`
                  }}
                </template>
                <template #cell(total_cost_finalizados)="row">
                  {{
                    `$${
                      row.item.total_cost_finalizados
                        ? Intl.NumberFormat("es-AR").format(
                            parseFloat(row.item.total_cost_finalizados)
                          )
                        : 0
                    }`
                  }}
                </template>
                <template #cell(received)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'received',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.received }}
                  </div>
                </template>
                <template #cell(pending)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'pending',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.pending }}
                  </div>
                </template>
                <template #cell(finished)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'finished',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.finished }}
                  </div>
                </template>
                <template #cell(derived)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'derived',
                        'received',
                        'status',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ getDerived(row.item.area_id, false) }}
                  </div>
                </template>
                <template #cell(average_reading)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'reading',
                        'received',
                        'time',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.average_reading }}
                  </div>
                </template>
                <template #cell(average_intake)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'take',
                        'received',
                        'time',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.average_intake }}
                  </div>
                </template>
                <template #cell(average_resolution)="row">
                  <div
                    class="pointer"
                    @click="
                      getTicketsSpecific(
                        row.item.area_id,
                        'resolution',
                        'received',
                        'time',
                        row.item.area_name,
                        'recibidos'
                      )
                    "
                  >
                    {{ row.item.average_resolution }}
                  </div>
                </template>
              </b-table>
            </b-col>
          </div>
        </b-row>
      </b-overlay>
    </div>
    <!-- Msjs modal -->
    <b-modal id="msj-modal" ref="msj-modal" centered hide-footer hide-header>
      <div class="d-block text-center p-4">
        <h4>{{ msjModal }}</h4>
      </div>
      <b-button
        v-if="!isDelete"
        class="mt-3"
        variant="outline-primary"
        block
        size="sm"
        @click="$bvModal.hide('msj-modal')"
        >OK</b-button
      >
      <b-row v-if="isDelete">
        <b-col cols="6" align="center">
          <b-button
            class="mt-3"
            variant="secondary"
            @click="$bvModal.hide('msj-modal')"
            >Cancelar</b-button
          >
        </b-col>
        <b-col cols="6" align="center">
          <b-button class="mt-3" variant="danger" @click="deleteOperation"
            >Confirmar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <!-- Tickets modal -->
    <b-modal
      id="detail-tickets-modal"
      size="xl"
      centered
      hide-footer
      :title="`Tickets - Agente ${agentSelected ? agentSelected.name : ''}`"
    >
      <template>
        <button
          type="button"
          class="close"
          @click="$bvModal.hide('detail-tickets-modal')"
        ></button>
      </template>
      <b-overlay
        :show="showLoadingModal"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <div>
          <b-col md="12" class="pb-2">
            <b-row>
              <b-col cols="11">
                <b-input-group class="mb-3">
                  <b-form-input
                    size="sm"
                    v-model="filter"
                    placeholder="Buscar..."
                    class="mr-2"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col
                v-if="itemsModal.length"
                cols="1"
                class="pointer"
                align="right"
              >
                <b-button
                  @click="downloadExcel(itemsModal, 'modal')"
                  size="sm"
                  variant="outline-primary"
                >
                  <b-icon
                    title="Exportar excel"
                    style="color: #0076ad"
                    icon="file-earmark-excel-fill"
                  ></b-icon>
                </b-button>
              </b-col>
            </b-row>
            <b-table
              class="table-rotation"
              responsive
              head-variant="light"
              fixed
              bordered
              small
              striped
              hoverstriped
              hover
              :filter="filter"
              :items="itemsModal"
              :fields="fieldsModal"
              :current-page="currentPage"
              :per-page="perPage"
            >
              <template #cell(status)="row">
                <b-badge variant="info">{{
                  row.item.status != "finalizado ok"
                    ? row.item.status
                    : "verificado"
                }}</b-badge>
              </template>
              <template #cell(cost)="row">
                {{
                  `$${
                    row.item.cost
                      ? Intl.NumberFormat("es-AR").format(
                          parseFloat(row.item.cost)
                        )
                      : 0
                  }`
                }}
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              class="mt-3"
            ></b-pagination>
          </b-col>
        </div>
      </b-overlay>
    </b-modal>
  </b-card>
</template>

<script>
import XLSX from "../../libraries/xlsx/xlsx.full.min";
export default {
  props: ["rol"],
  data() {
    return {
      statusSelected: "todos",
      statusList: [
        { text: "Todos", value: "todos" },
        { text: "En Proceso", value: "en_proceso" },
        { text: "Finalizado", value: "finalizado" },
      ],
      categories: [],
      categorySelected: 0,
      currentPage: 1,
      perPage: 10,
      filter: "",
      itemsByAgents: [],
      isBoss: false,
      typeList: [
        { text: "Sin Consulta Bancaria", value: "no_consulta_bancaria" },
        { text: "Consulta Bancaria", value: "consulta_bancaria" },
        { text: "Todos", value: "todos" },
      ],
      typeSelected: "no_consulta_bancaria",
      selectionMode: "days", // Modo de entrada seleccionado ('days' o 'dates')
      isVerifiedTickets: false,
      dateRangeSelected: null, // Modelo para el selector de rango de días
      startDate: "", // Fecha de inicio
      endDate: "", // Fecha de fin
      isDaysRangeEnabled: true, // Controla el estado del selector de rango de días
      isDateRangeEnabled: false, // Controla el estado de los campos de fecha
      showFilters: true,
      selectedPeriod: "all",
      options: [
        { text: "Hoy", value: "today" },
        { text: "30 días", value: "30days" },
        { text: "Todos los registros", value: "all" },
      ],
      parametersTitleModal: {
        areaName: null,
        type: null,
      },
      cellhover: false,
      userCanAdd: null,
      dateRangeSelected: 0,
      dateRangeRealizedSelected: 1,
      dateRangeList: [
        { text: "Seleccione una opción...", value: null },
        { text: "Hoy", value: 0 },
        { text: "30 días", value: 1 },
        { text: "60 días", value: 2 },
        { text: "90 días", value: 3 },
        { text: "Todos", value: 4 },
      ],
      msjModal: "",
      items: [],
      itemsModal: [],
      itemsRealized: [],
      itemsModalTickets: [],
      derivados: [],
      derivadosRealized: [],
      show: false,
      userId: null,
      rolId: null,
      areaId: null,
      fieldsAgents: [],
      fieldsAgentsWithOutVerified: [
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_en_proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_finalizados",
          label: "FINALIZADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "average_completion_time",
          label: "TIEMPO MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fieldsAgentsWithVerified: [
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_en_proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_finalizados",
          label: "FINALIZADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_verificados",
          label: "VERIFICADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "average_completion_time",
          label: "TIEMPO MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "average_verification_time",
          label: "TIEMPO MEDIO VERIFICACIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fields: [],
      fieldsWithOutVerified: [
        {
          key: "area_destino",
          label: "AREA",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "pendiente",
          label: "PENDIENTE",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "leído",
          label: "LEÍDO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "en proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado",
          label: "FINALIZADO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[1]",
          label: "T.MEDIO LECTURA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[2]",
          label: "T.MEDIO TOMA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[3]",
          label: "T.MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fieldsWithVerified: [
        {
          key: "area_destino",
          label: "AREA",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "pendiente",
          label: "PENDIENTE",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "leído",
          label: "LEÍDO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "en proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado",
          label: "FINALIZADO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado ok",
          label: "VERIFICADO",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "times[1]",
          label: "T.MEDIO LECTURA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[2]",
          label: "T.MEDIO TOMA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "times[3]",
          label: "T.MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "80px" },
        },
      ],
      fieldsRealized: [
        {
          key: "area_origen",
          label: "AREA",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "pendiente",
          label: "PENDIENTE",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "leído",
          label: "LEÍDO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "en proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado",
          label: "FINALIZADA",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "finalizado ok",
          label: "VERIFICADA",
          thStyle: { width: "95px" },
          sortable: true,
        },
      ],
      fieldsModal: [],
      fieldsModalDefault: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "30px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "120px" },
          sortable: true,
        },
        {
          key: "status",
          label: "ESTADO",
          thStyle: { width: "80px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
          sortable: true,
        },
      ],
      fieldsModalWithCost: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "30px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "120px" },
          sortable: true,
        },
        {
          key: "status",
          label: "ESTADO",
          thStyle: { width: "80px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
          sortable: true,
        },
        {
          key: "category_description",
          label: "TIPO",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "cost",
          label: "CATEGORIA($)",
          thStyle: { width: "80px" },
          tdClass: ["text-right"],
          thClass: ["text-right"],
          sortable: true,
        },
      ],
      fieldsModalTime: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "95px" },
          sortable: true,
        },
        {
          key: "time",
          label: "TIEMPO",
          thStyle: { width: "95px" },
          sortable: true,
        },
      ],
      fieldsModalStatus: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "100px" },
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCION",
          thStyle: { width: "95px" },
          sortable: true,
        },
        /*{
          key: "time",
          label: "TIEMPO",
          thStyle: { width: "95px" },
          sortable: true,
        },*/
      ],
      showLoadingModal: false,
      isDelete: false,
      rowModalSelected: null,
      selectedArea: null,
      agentSelected: null,
      defaultFieldsAgents: [
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_en_proceso",
          label: "EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "cant_tickets_finalizados",
          label: "FINALIZADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "average_completion_time",
          label: "TIEMPO MEDIO RESOLUCIÓN",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "total_cost_en_proceso",
          label: "TOTAL EN PROCESO",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "total_cost_finalizados",
          label: "TOTAL FINALIZADOS",
          thStyle: { width: "80px" },
          sortable: true,
        },
        { key: "actions", label: "", thStyle: { width: "80px" } },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    area() {
      return this.$store.getters.getArea;
    },
    isEndDateValid() {
      return this.endDate >= this.startDate || this.endDate === "";
    },
    isFormValid() {
      return this.startDate !== "" && this.isEndDateValid;
    },
    totalRows() {
      return this.itemsModal.length;
    },
  },
  mounted() {
    if (this.area.id == 3) {
      this.typeSelected = "todos";
    }
    //Verificamos si el usuario cuenta con un area asignada
    if (this.area != undefined) {
      this.getUser();
      this.getArea();
      this.getTicketsFilterRange();
      this.getCategories();
    } else {
      this.userWithoutArea = true;
    }
    this.fieldsAgents = this.fieldsAgentsWithVerified;
  },
  methods: {
    filterAgentsTicketsByStatus() {
      if (this.statusSelected == "todos") {
        // Restaurar las columnas al orden por defecto
        this.fieldsAgents = [...this.defaultFieldsAgents];
      } else if (this.statusSelected == "en_proceso") {
        // Quitar las claves específicas
        this.fieldsAgents = this.defaultFieldsAgents.filter(
          (field) =>
            field.key !== "cant_tickets_finalizados" &&
            field.key !== "total_cost_finalizados"
        );

        // Agregar las necesarias (aunque ya están presentes en defaultFieldsAgents, esto asegura el orden)
        this.fieldsAgents = this.fieldsAgents.filter((field) =>
          [
            "name",
            "cant_tickets_en_proceso",
            "total_cost_en_proceso",
            "average_completion_time",
            "actions",
          ].includes(field.key)
        );
      } else if (this.statusSelected == "finalizado") {
        // Quitar las claves específicas
        this.fieldsAgents = this.defaultFieldsAgents.filter(
          (field) =>
            field.key !== "cant_tickets_en_proceso" &&
            field.key !== "total_cost_en_proceso"
        );

        // Agregar las necesarias (aunque ya están presentes en defaultFieldsAgents, esto asegura el orden)
        this.fieldsAgents = this.fieldsAgents.filter((field) =>
          [
            "name",
            "cant_tickets_finalizados",
            "total_cost_finalizados",
            "average_completion_time",
            "actions",
          ].includes(field.key)
        );
      }
    },
    getItemsWithHeadForExcel(items, typeHead) {
      if (typeHead == "received") {
        let itemsAux = items.map((item) => {
          return {
            AREA: item.area_destino,
            PENDIENTE: item.pendiente,
            LEÍDO: item.leído,
            EN_PROCESO: item["en proceso"],
            FINALIZADO: item.finalizado,
            VERIFICADO: item["finalizado ok"],
            T_MEDIO_LECTURA: item.times["1"],
            T_MEDIO_TOMA: item.times["2"],
            T_MEDIO_RESOLUCIÓN: item.times["3"],
          };
        });
        if (this.typeSelected == "no_consulta_bancaria") {
          itemsAux.forEach((item) => {
            delete item.VERIFICADO;
          });
        }
        return itemsAux;
      } else if (typeHead == "agent") {
        return items.map((item) => {
          const baseData = {
            NOMBRE: item.name,
            EN_PROCESO: item.cant_tickets_en_proceso,
            FINALIZADOS: item.cant_tickets_finalizados,
            DERIVADOS: item.cant_tickets_derivados,
            TIEMPO_MEDIO_RESOLUCIÓN: item.average_completion_time,
          };

          // Agregar propiedades adicionales si el área es 12
          if (this.selectedArea.areaId === 12) {
            return {
              ...baseData,
              TOTAL_EN_PROCESO: item.total_cost_en_proceso,
              TOTAL_FINALIZADOS: item.total_cost_finalizados,
            };
          }

          return baseData;
        });
      } else if (typeHead == "modal") {
        // Si es con CATEOGORIA necesitamos agregar y calcular columna VALOR
        if (this.selectedArea.areaId != 12) {
          if (this.selectedArea.areaId == 2) {
            return items.map((item) => {
              return {
                ID: item.id,
                DESCRIPCIÓN: item.description,
                CREADOR: item.name_user_creator,
                ESTADO: item.status,
                TIEMPO_RES: item.response_time,
              };
            });
          } else {
            return items.map((item) => {
              return {
                ID: item.id,
                DESCRIPCIÓN: item.description,
                ESTADO: item.status,
              };
            });
          }
        } else {
          let totalCost = 0;
          const itemsForExcel = items.map((item) => {
            totalCost += Number(item.cost ?? 0);
            return {
              ID: item.id,
              DESCRIPCIÓN: item.description,
              ESTADO: item.status,
              TIPO: item.category_description,
              VALOR: Number(item.cost),
            };
          });
          itemsForExcel.push({
            ID: "",
            DESCRIPCIÓN: "",
            ESTADO: "",
            TIPO: "",
            VALOR: totalCost,
          });
          return itemsForExcel;
        }
      }
    },
    getFileName(typeHead) {
      let name = {
        received: "general",
        agent: this.selectedArea ? this.selectedArea.area_destino : "",
        modal: this.agentSelected ? this.agentSelected.name : "",
      };
      return `tickets_${name[typeHead]}_${new Date()
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-")}.xlsx`;
    },
    downloadExcel(items, typeHead) {
      const itemsExcel = this.getItemsWithHeadForExcel(items, typeHead);
      const fileName = this.getFileName(typeHead);
      const data = XLSX.utils.json_to_sheet(itemsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "hoja1");
      XLSX.writeFile(wb, fileName);
    },
    getCategories() {
      this.showLoadingModal = true;
      this.$axios.get("/order/getCategories").then((response) => {
        this.categories = response.data.map((category) => {
          category.text = `${category.value} - ${category.text} -  $${
            category.cost
              ? Intl.NumberFormat("es-AR").format(parseFloat(category.cost))
              : "no definido"
          }`;
          category.value = category.value;
          return category;
        });
        this.categories.unshift({
          text: "Todos",
          value: 0,
        });
        this.showLoadingModal = false;
      });
      return;
    },
    getTicketsFromAgent(agent) {
      this.show = true;
      this.agentSelected = agent;
      this.$axios
        .get("/order/getTicketsFromAgent", {
          params: {
            range_month: this.dateRangeSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            isDateRangeEnabled: this.isDateRangeEnabled,
            type: this.typeSelected,
            rol_id: this.rolId,
            agent_id: agent.id,
            category: this.categorySelected,
          },
        })
        .then((response) => {
          this.itemsModal = response.data.map((item) => {
            let hour = Math.floor(item.response_time / 60);
            let minute = item.response_time % 60;
            // let second = item.response_time/60
            item.response_time = `${hour}h ${minute}m`;
            return item;
          });
          this.fieldsModal =
            this.selectedArea.areaId != 12
              ? this.fieldsModalDefault
              : this.fieldsModalWithCost;
          /// cuando es del area de soporte se agregan
          if (this.selectedArea.areaId == 2) {
            this.fieldsModalDefault.splice(2, 0, {
              key: "name_user_creator",
              label: "CREADOR",
              thStyle: { width: "30px" },
              sortable: true,
            });
            this.fieldsModalDefault.push({
              key: "response_time",
              label: "TIEMPO RES.",
              thStyle: { width: "30px" },
              sortable: true,
            });
          }

          this.$bvModal.show("detail-tickets-modal");
          this.show = false;
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    getAgentsByArea(item) {
      this.show = true;
      this.selectedArea = item;
      this.$axios
        .get("/order/getAgentsByArea", {
          params: {
            range_month: this.dateRangeSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            isDateRangeEnabled: this.isDateRangeEnabled,
            type: this.typeSelected,
            area_id: item.areaId,
            rol_id: this.rolId,
            user_id: this.userId,
            category: this.categorySelected,
          },
        })
        .then((response) => {
          this.itemsByAgents = response.data.agentsStatistics;
          //SI es area mantenimiento, agregamos las columnas para reflejar los totales
          if (item.areaId == 12) {
            const newFields = [
              {
                key: "total_cost_en_proceso",
                label: "TOTAL EN PROCESO",
                thStyle: { width: "80px" },
                sortable: true,
              },
              {
                key: "total_cost_finalizados",
                label: "TOTAL FINALIZADOS",
                thStyle: { width: "80px" },
                sortable: true,
              },
            ];
            // Insertar a partir del anteúltimo elemento
            const insertPosition = Math.max(0, this.fieldsAgents.length - 1);
            this.fieldsAgents.splice(insertPosition, 0, ...newFields);
          } else {
            // Eliminar los campos agregados en el if
            this.fieldsAgents = this.fieldsAgents.filter(
              (field) =>
                !["total_cost_en_proceso", "total_cost_finalizados"].includes(
                  field.key
                )
            );
          }
          this.show = false;
          const elemento = this.$refs.agentsTable;
          if (elemento) {
            elemento.scrollIntoView({ behavior: "smooth" });
          }
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    clearInputs() {
      if (this.selectionMode === "days") {
        this.isDateRangeEnabled = false;
        this.isDaysRangeEnabled = true;
        this.dateRangeSelected = null;
      } else if (this.selectionMode === "dates") {
        this.isDateRangeEnabled = true;
        this.isDaysRangeEnabled = false;
      }
      this.items = [];
    },
    getTicketsSpecific(areaId, type, tableName, sorting, areaName, typeName) {
      this.parametersTitleModal.areaName = areaName;
      this.parametersTitleModal.type = typeName;
      this.show = true;
      this.$axios
        .get("/order/getTicketsSpecific", {
          params: {
            area_id: areaId,
            type: type,
            table_name: tableName,
            sorting: sorting,
          },
        })
        .then((response) => {
          this.itemsModalTickets = [...response.data];
          if (sorting == "time") {
            this.fieldsModal = this.fieldsModalTime;
          } else {
            this.fieldsModal = this.fieldsModalStatus;
          }
          this.$bvModal.show("detail-tickets-modal");
          this.show = false;
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    getTicketsFilterRange() {
      this.selectedArea = null;
      this.itemsByAgents = null;
      this.show = true;
      this.$axios
        .get("/order/getStatistics", {
          params: {
            range_month: this.dateRangeSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            isDateRangeEnabled: this.isDateRangeEnabled,
            type: this.typeSelected,
            category: this.categorySelected,
            area_id: this.areaId,
            rol_id: this.rolId,
            user_id: this.userId,
          },
        })
        .then((response) => {
          //Tiempos
          const averagesByAreaTicketsReceived =
            response.data.averagesByAreaTicketsReceived;
          const ticketsReceived = response.data.ticketsReceived.reduce(
            (acc, item) => {
              let area = acc.find((a) => a.area_destino === item.area_destino);
              if (!area) {
                area = {
                  areaId: item.area_id,
                  area_destino: item.area_destino,
                  pendiente: 0,
                  leído: 0,
                  "en proceso": 0,
                  finalizado: 0,
                  rechazado: 0,
                  derivado: 0,
                  "finalizado ok": 0,
                };
                acc.push(area);
              }
              area[item.status_name] = parseInt(item.total_tickets);
              return acc;
            },
            []
          );
          this.items = ticketsReceived;
          this.items.map((item) => {
            if (averagesByAreaTicketsReceived.hasOwnProperty(item.areaId)) {
              item.times = averagesByAreaTicketsReceived[item.areaId];
            } else {
              item.times = [];
            }
            return item;
          });
          //Verificamos el typo de ticket seleccionado
          if (this.typeSelected == "no_consulta_bancaria") {
            this.fields = this.fieldsWithOutVerified;
            this.fieldsAgents = this.fieldsAgentsWithOutVerified;
          } else if (this.typeSelected == "consulta_bancaria") {
            this.fields = this.fieldsWithVerified;
            this.fieldsAgents = this.fieldsAgentsWithVerified;
          } else if (this.typeSelected == "todos") {
            this.fields = this.fieldsWithVerified;
            this.fieldsAgents = this.fieldsAgentsWithVerified;
          }
          //Verificamos si es un JEFE, para mostrar la tabla de agentes
          if (this.rolId != 1 && this.userId != 5) {
            this.isBoss = true;
            this.itemsByAgents = response.data.agentsStatistics;
            this.selectedArea = this.items[0];
            //SI es area mantenimiento, agregamos las columnas para reflejar los totales
            if (this.selectedArea && this.selectedArea.areaId == 12) {
              const newFields = [
                {
                  key: "total_cost_en_proceso",
                  label: "TOTAL EN PROCESO",
                  thStyle: { width: "80px" },
                  sortable: true,
                },
                {
                  key: "total_cost_finalizados",
                  label: "TOTAL FINALIZADOS",
                  thStyle: { width: "80px" },
                  sortable: true,
                },
              ];
              // Insertar a partir del anteúltimo elemento
              const insertPosition = Math.max(0, this.fieldsAgents.length - 1);
              this.fieldsAgents.splice(insertPosition, 0, ...newFields);
            } else {
              // Eliminar los campos agregados en el if
              this.fieldsAgents = this.fieldsAgents.filter(
                (field) =>
                  !["total_cost_en_proceso", "total_cost_finalizados"].includes(
                    field.key
                  )
              );
            }
          }
          this.show = false;
        })
        .catch((err) => {
          this.show = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    getDerived(areaId, isRealized) {
      let area = null;
      if (isRealized) {
        area = this.derivadosRealized.find((e) => e.id == areaId);
      } else {
        area = this.derivados.find((e) => e.id == areaId);
      }
      if (area) {
        return area.derived;
      }
      return 0;
    },
    goToList() {
      window.location.href = "/tickets";
    },
    getUser() {
      //console.log(this.user.roles[0].id)
      this.userId = this.user.id;
      this.rolId = this.user.roles[0].id;
      // quito permisos a usuario que no es rol 1 2 3
    },
    getArea() {
      this.areaId = this.area.id;
    },
  },
};
</script>
<style scoped>
.subtitle {
  background-color: #0076ad;
  color: white;
  padding: 3pt;
  margin-bottom: 3pt;
}
.filter-collapse {
  background-color: #f1f7fb;
  padding: 3pt 3pt 1pt 3pt;
}
</style>